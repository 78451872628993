<section class="section projects" id="projects">
	<div class="container">
		<div class="section-box">
			<div data-aos="fade-up" data-aos-duration="1000">
				<h3 class="section-title">
					<span class="n-section-title">03.</span>
					{{ "FeatureProjects.Title" | translate }}
				</h3>
			</div>

			<div
				*ngFor="
					let project of 'FeatureProjects.Projects' | translate;
					let i = index
				"
				class="d-flex project-container"
				data-aos="fade-up"
				data-aos-duration="1000"
			>
				<owl-carousel-o
					class="owl-carousel-o"
					[options]="customOptions"
					*ngIf="i % 2 == 0"
				>
					<ng-container *ngFor="let img of project.imgs">
						<ng-template carouselSlide [id]="project.Title + slide + i">
							<div #imgContainer class="img-container">
								<img
									class="img-feature-project rounded"
									[src]="img"
									[alt]="project.Title"
								/>
							</div>
						</ng-template>
					</ng-container>
				</owl-carousel-o>
				<div
					class="img-feature-project-container"
					data-aos="fade-up"
					data-aos-duration="1000"
					*ngIf="i % 2 == 0"
				>
					<div class="img-feature-project-box">
						<div class="img-container" style="width: auto">
							<img
								class="img-feature-project rounded"
								[src]="project.imgs[0]"
								[alt]="project.imgs[0]"
								width="600px"
							/>
						</div>
					</div>
				</div>
				<div
					[class.project-info-right]="i % 2 == 0"
					[class.project-info-left]="i % 2 != 0"
					class="h-100"
					style="background-image: url('{{ project.img }}');"
				>
					<div class="project-box">
						<h4
							[class.text-right]="i % 2 == 0"
							[class.text-left]="i % 2 != 0"
							class="feature-project"
						>
							{{ "FeatureProjects.Label" | translate }}
						</h4>
						<h5
							[class.text-right]="i % 2 == 0"
							[class.text-left]="i % 2 != 0"
							class="project-name"
						>
							{{ project.Title }}
						</h5>

						<div class="project-description-box" (click)="debug()">
							<p class="mb-0" [innerHTML]="project.Description"></p>
						</div>
						<ul
							[class.justify-content-end]="i % 2 == 0"
							[class.justify-content-start]="i % 2 != 0"
							class="project-skills"
						>
							<li *ngFor="let technology of project.Technologies" class="ml-3">
								<span class="underline technology">{{ technology }}</span>
							</li>
						</ul>
						<div
							[class.text-right]="i % 2 == 0"
							[class.text-left]="i % 2 != 0"
							class="project-links"
						>
							<a
								*ngIf="project['ghLink']"
								style="color: inherit"
								[href]="project['ghLink']"
								target="_blank"
								><i class="ml-3 fab fa-github"></i
							></a>
							<a
								*ngIf="project['demoLink']"
								style="color: inherit"
								[href]="project['demoLink']"
								target="_blank"
								><i class="ml-3 fas fa-external-link-alt"></i
							></a>
						</div>
					</div>
				</div>
				<owl-carousel-o
					class="owl-carousel-o"
					[options]="customOptions"
					*ngIf="i % 2 != 0"
				>
					<ng-container *ngFor="let img of project.imgs">
						<ng-template carouselSlide [id]="project.Title + slide + i">
							<div class="img-container">
								<img
									class="img-feature-project rounded"
									[src]="img"
									[alt]="project.Title"
								/>
							</div>
						</ng-template>
					</ng-container>
				</owl-carousel-o>
				<div
					class="img-feature-project-container"
					data-aos="fade-up"
					data-aos-duration="1000"
					*ngIf="i % 2 != 0"
				>
					<div class="img-feature-project-box">
						<div class="img-container" style="width: auto">
							<img
								class="img-feature-project rounded"
								[src]="project.imgs[0]"
								[alt]="project.imgs[0]"
								width="600px"
							/>
						</div>
					</div>
				</div>
			</div>
			<div></div>
		</div>
	</div>
</section>
